import logos from "../img/logos/*.*";
import devent from "../img/devent/*.*";
import slides from "../img/sensors/*.*";
import layers from "../img/layers/*.png";
import news from "../img/news/11042022/*.jpg";
import {getAsync} from "./utils";

export var config = {
  backEndUrl: 'https://preprod-backend.windmorbihan.com',
  favoritePath: localStorage.getItem('path'),
  newsDisplay: localStorage.getItem('news'),
  mapCenter: getMapCenter(),
  mapZoom: getMapZoom(),
  mapBounds: [[48.5, -5.0], [46.5, -1.5]],
  landColor: '#9AF988',
  coastColor: '#154194',
  popup: null,
  latFreq: 7,
  lngFreq: 10,
  windRanges: [[0, 5], [5, 8], [8, 11], [11, 14], [14, 17], [17, 20], [20, 23], [23, 26], [26, 29], [29, 33], [33, 37],[37, 41], [41, 46], [46, 50], [50, 9999]],
  windColors: {
    c1: '#a5f1f8',
    c2: '#49e1f5',
    c3: '#17ddce',
    c4: '#0be678',
    c5: '#86e725',
    c6: '#f0ea00',
    c7: '#ffcc00',
    c8: '#ff9900',
    c9: '#ff6600',
    c10: '#ff0000',
    c11: '#e6005b',
    c12: '#d60093',
    c13: '#a60ec4',
    c14: '#7c2fab',
    c15: '#58166c'
  },
  windLegendRanges: [
    {bLabel: "< 2", kLabel: "< 7", kmLabel: "< 12", color: "#69e6f7"},
    {bLabel: "3", kLabel: "7 - 10", kmLabel: "12 - 19", color: "#15d9cf"},
    {bLabel: "4", kLabel: "11 - 16", kmLabel: "20 - 28", color: "#0be678"},
    {bLabel: "5", kLabel: "17 - 21", kmLabel: "29 - 38", color: "#f0ea00"},
    {bLabel: "6", kLabel: "22 - 27", kmLabel: "39 - 49", color: "#ff9900"},
    {bLabel: "7", kLabel: "28 - 33", kmLabel: "50 - 61", color: "#ff0000"},
    {bLabel: "8", kLabel: "34 - 40", kmLabel: "62 - 74", color: "#d60093"},
    {bLabel: "9", kLabel: "41 - 47", kmLabel: "75 - 88", color: "#a60ec4"},
    {bLabel: "> 10", kLabel: "> 48", kmLabel: "> 89", color: "#762da3"}
  ],
  wavesLegendRanges: [
    {label: "Belle", height: "< 0,4m", color: "#f5f5f5"},
    {label: "Peu agitée", height: "0,4 à 1m", color: "#b3f0f5"},
    {label: "(Peu agitée) à Agitée", height: "1 à 2m", color: "#9ef5bf"},
    {label: "(Agitée) à Forte", height: "2m à 4m", color: "#f5f58f"},
    {label: "Très forte", height: "4 à 6m", color: "#f58787"},
    {label: "Grosse à énorme", height: "> 6m", color: "#e18cf5"}
  ],
  beaufortRanges: [[0, 7], [7, 11], [11, 17], [17, 22], [22, 28], [28, 34], [34, 41], [41, 48], [48, 999]],
  beaufortColors: {
    c1: '#69e6f7',
    c2: '#15d9cf',
    c3: '#0be678',
    c4: '#f0ea00',
    c5: '#ff9900',
    c6: '#ff0000',
    c7: '#d60093',
    c8: '#a60ec4',
    c9: '#762da3'
  },
  tempRanges: [[-99, 0], [0, 6], [6, 11], [11, 16], [16, 21], [21, 26], [26, 31], [31, 99]],
  tempColors: {
    c1: '#000099',
    c2: '#0000ff',
    c3: '#0099ff',
    c4: '#ffcc00',
    c5: '#ff8409',
    c6: '#ff3f3f',
    c7: '#d60093',
    c8: '#9900cc'
  },
  waveRanges: [[0, 0.4], [0.4, 0.6], [0.6, 0.8], [0.8, 1], [1, 1.3], [1.3, 1.6], [1.6, 2], [2, 2.5], [2.5, 3], [3, 3.5], [3.5, 4], [4, 5], [5, 6], [6, 9], [9, 99]],
  waveColors: {
    c0: 'rgb(255,255,255)',
    c1: 'rgb(232,250,252)',
    c2: 'rgb(185,250,255)',
    c3: 'rgb(130,230,250)',
    c4: 'rgb(148,250,214)',
    c5: 'rgb(147,251,162)',
    c6: 'rgb(124,244,108)',
    c7: 'rgb(238,253,123)',
    c8: 'rgb(255,255,93)',
    c9: 'rgb(255,226,101)',
    c10: 'rgb(255,184,113)',
    c11: 'rgb(255,140,113)',
    c12: 'rgb(255,109,168)',
    c13: 'rgb(255,125,255)',
    c14: 'rgb(224,87,247)'
  },
  periodRanges: [[0, 7], [7, 10], [10, 13], [13, 16], [16, 19], [19, 99]],
  periodColors: {
    c1: '#ffffff',
    c2: '#edf6fd',
    c3: '#d6ebfa',
    c4: '#bfdff7',
    c5: '#add6f5',
    c6: '#9bcdf3'
  },
  rainRanges: [[0, 3], [3, 5], [5, 7], [7, 99]],
  rainColors: {
    c1: '#fafefe',
    c2: '#eef6f8',
    c3: '#e1f0f3',
    c4: '#d8ecf0'
  },
  chartColors: {
    ws: {
      moy: "#00ff00",
      min: "#9eff9e",
      max: "#005500"
    },
    wd: {
      moy: "#4a4aff",
      min: "#b2b2ff",
      max: "#0000ff"
    },
    t: {
      moy: "#000000"
    },
    p: {
      moy: "#000000"
    },
    h: {
      moy: "#000000"
    },
    wv: {
      moy: "#000000"
    },
    sh: {
      moy: "#000000"
    },
    sp: {
      moy: "#000000"
    },
    sd: {
      moy: "#000000"
    }
  }
};
export let waveSensorRef = {};
export var tempSensorsRefs = [];
export var waterTempSensorId = 7557;
export var waterTempSensorsRefs = [];
export var sensorsRefs = {};
export var webcamsRefs = [];
export var harborsRefs = [];
export var advertisersRefs = [];
export var newsRefs = Object.values(news).sort().map(function(n) {return {path: n}});

export function loadSensors(onComplete) {
  getAsync(config.backEndUrl + '/capteurs/list.json', function(data) {
    sensorsRefs = (data.sensors["WindSensor"] || []);
    waveSensorRef = data.sensors["WaveSensor"];
    tempSensorsRefs = (data.sensors["TempSensor"] || []).filter(s => s.nid !== waterTempSensorId);
    waterTempSensorsRefs = (data.sensors["TempSensor"] || []).filter(s => s.nid === waterTempSensorId);
    onComplete(data);
  });
}

export function loadWebcams(onComplete) {
  getAsync(config.backEndUrl + '/webcams/list.json', function(data) {
    webcamsRefs = data.webcams;
    onComplete(data);
  });
}
export function loadHarbors(onComplete) {
  getAsync(config.backEndUrl + '/harbors/list.json', function(data) {
    harborsRefs = data.harbors;
    onComplete(data);
  });
}

export function loadAdvertisers(onComplete) {
  getAsync(config.backEndUrl + '/annonceurs/list.json', function(data) {
    advertisersRefs = data.advertisers;
    onComplete(data);
  });
}


export var asides = {
  73091273: [
    {links: [{img: assetPath(logos, 'logo_cerema'), href: 'https://www.cerema.fr/'}]}, {map: {lat: 47.283, lng: -3.287}},
    {devent: false}
  ],
  73091304: [
    {links: [{img: assetPath(logos, 'logo_aqta'), href: 'https://www.auray-quiberon.fr/'}]},
    {markup: '<section class="sensor_description"><h2>Description de l\'installation</h2><ul><li>Capteur LCJ CV7</li><li>Hauteur : <strong>6,60m au dessus du toit, soit environ 12m au dessus de la mer</strong></li></ul><p><small>Nouveau capteur LCJ mis en service mardi 21/07/2015.</small></p></section>'},
    {devent: assetPath(devent, '7557')},
    {slides: [assetPath(slides, '7557-1'), assetPath(slides, '7557-2')]},
    {map: {lat: 47.567000, lng: -3.004000}}
  ],
  10380037: [
    {links: [{img: assetPath(logos, 'logo_dirm'), href: 'http://www.dirm.nord-atlantique-manche-ouest.developpement-durable.gouv.fr/'}]},
    {markup: '<section class="sensor_description"><h2>Description de l\'installation</h2><ul><li>Capteur LCJ CV7</li><li>Hauteur : 1,50m au dessus de la coupole, soit <strong>22m au dessus de la terre</strong></li></ul></section>'},
    {devent: assetPath(devent, '10380037')},
    {slides: [assetPath(slides, '10380037-1'), assetPath(slides, '10380037-2'), assetPath(slides, '10380037-3')]},
    {map: {lat: 47.547800, lng: -2.918300}}
  ],
  10438252: [
    {links: [{img: assetPath(logos, 'logo_aqta'), href: 'https://www.auray-quiberon.fr/'}]},
    {markup: '<section class="sensor_description"><h2>Description de l\'installation</h2><ul><li>Capteur LCJ CV7</li><li>Hauteur : <strong>7m au dessus du toit, soit 17m au dessus de la terre, soit environ 25m au dessus de la mer</strong></li></ul></section>'},
    {devent: assetPath(devent, '10438252')},
    {slides: [assetPath(slides, '10438252-1'), assetPath(slides, '10438252-2'), assetPath(slides, '10438252-3')]},
    {map: {lat: 47.509500, lng: -3.119400}}
  ],
  73091265: [
    {links: [{img: assetPath(logos, 'logo_aqta'), href: 'https://www.auray-quiberon.fr/'}]},
    {markup: '<section class="sensor_description"><h2>Description de l\'installation</h2><ul><li>Capteur LCJ CV7</li><li>Hauteur : 6m au dessus du toit, soit <strong>9m au dessus de la terre</strong></li></ul></section>'},
    {devent: assetPath(devent, '73091265')},
    {slides: [assetPath(slides, '73091265-1'), assetPath(slides, '73091265-2'), assetPath(slides, '73091265-3')]},
    {map: {lat: 47.550833, lng: -3.134722}}
  ],
  73091277: [
    {markup: '<section class="sensor_description"><h2>Description de l\'installation</h2><ul><li>Capteur LCJ CV7</li><li>Hauteur : en haut du sémaphore d\'Etel, soit <strong>16m au dessus de la terre</strong></li></ul></section>'},
    {devent: false},
    {slides: [assetPath(slides, '73091277-1')]},
    {map: {lat: 47.646112, lng: -3.214433}}
  ],
  73091279: [
    {links: [{img: assetPath(logos, 'logo_ecn'), href: 'https://www.ec-nantes.fr/'}, {img: assetPath(logos, 'logo_dirm'), href: 'http://www.dirm.nord-atlantique-manche-ouest.developpement-durable.gouv.fr/'}, {img: assetPath(logos, 'logo_aqta'), href: 'https://www.auray-quiberon.fr/'}]},
    {markup: '<section class="sensor_description"><h2>Description de l\'installation</h2><ul><li>Capteur Gill Windmaster pro 3D</li><li>Hauteur : <strong>29m au dessus de la mer</strong></li></ul></section>'},
    {devent: assetPath(devent, '73091279')},
    {slides: [assetPath(slides, '73091279-1')]},
    {map: {lat: 47.457333, lng: -3.045767}}
  ],
  73091286: [
    {links: [{img: assetPath(logos, 'logo_lorient'), href: 'https://www.lorient-agglo.bzh/'}, {img: assetPath(logos, 'logo_sellor'), href: 'https://www.sellor.com/'}, {img: assetPath(logos, 'logo_dirm'), href: 'http://www.dirm.nord-atlantique-manche-ouest.developpement-durable.gouv.fr/'}]},
    {markup: '<section class="sensor_description"><h2>Description de l\'installation</h2><ul><li>Capteur Gill Windmaster 3D</li><li>Hauteur : <strong>10m au dessus de la terre</strong></li></section>'},
    {devent: false},
    {slides: [assetPath(slides, '73091286-1'), assetPath(slides, '73091286-2')]},
    {map: {lat: 47.699518, lng: -3.460970}}
  ],
  73091264: [
    {links: [{img: assetPath(logos, 'logo_ecn'), href: 'https://www.ec-nantes.fr/'}, {img: assetPath(logos, 'logo_dirm'), href: 'http://www.dirm.nord-atlantique-manche-ouest.developpement-durable.gouv.fr/'}]},
    {markup: '<section class="sensor_description"><h2>Description de l\'installation</h2><ul><li>Capteur Gill Windmaster pro 3D</li><li>Hauteur : <strong>34m au dessus de la mer</strong></li></ul></section>'},
    {devent: assetPath(devent, '73091264')},
    {slides: [assetPath(slides, '73091264-1'), assetPath(slides, '73091264-2'), assetPath(slides, '73091264-3'), assetPath(slides, '73091264-4')]},
    {map: {lat: 47.321217,  lng: -2.834867}}
  ],
  73091293: [
    {links: [{img: assetPath(logos, 'logo_arz'), href: 'https://www.mairie-iledarz.fr/'}, {img: assetPath(logos, 'logo_vannes'), href: 'https://www.golfedumorbihan-vannesagglomeration.bzh/'}]},
    {markup: '<section class="sensor_description"><h2>Description de l\'installation</h2><ul><li>Capteur LCJ CV7</li><li>Hauteur : <strong>6m</strong></li></ul></section>'},
    {devent: assetPath(devent, '73091293')},
    {slides: [assetPath(slides, '73091293')]},
    {map: {lat: 47.595000, lng: -2.810440}}
  ],
  73091300: [
    {links: [{img: assetPath(logos, 'logo_lorient'), href: 'https://www.lorient-agglo.bzh/'}, {img: assetPath(logos, 'logo_ecn'), href: 'https://www.ec-nantes.fr/'}, {img: assetPath(logos, 'logo_dirm'), href: 'http://www.dirm.nord-atlantique-manche-ouest.developpement-durable.gouv.fr/'}]},
    {markup: '<section class="sensor_description"><h2>Description de l\'installation</h2><ul><li>Capteur Gill Windmaster 3D</li><li>Hauteur : <strong>10m au dessus de la terre</strong></li></section>'},
    {devent: false},
    {slides: [assetPath(slides, '73091300')]},
    {map: {lat: 47.647736, lng: -3.509733}}
  ],
  73091302: [
    {links: [{img: assetPath(logos, 'logo_ports_loire_atlantique'), href: 'https://lesportsdeloireatlantique.fr/'},{img: assetPath(logos, 'logo_loire_atlantique_nautisme'), href: 'https://www.loire-atlantique-nautisme.fr/'}, {img: assetPath(logos, 'logo_dirm'), href: 'http://www.dirm.nord-atlantique-manche-ouest.developpement-durable.gouv.fr/'}]},
    {markup: '<section class="sensor_description"><h2>Description de l\'installation</h2><ul><li>Capteur LCJ CV7</li><li>Hauteur : <strong>10m au dessus de la terre</strong></li></section>'},
    {devent: assetPath(devent, '73091302')},
    {slides: [assetPath(slides, '73091302')]},
    {map: {lat: 47.411505, lng: -2.620043}}
  ],
  7557: [
    {links: [{img: assetPath(logos, 'logo_crc'), href: 'http://www.huitres-de-bretagne.com/'}]}, {map: {lat: 47.55183, lng: -3.089282}},
    {devent: false},
    {slides: [assetPath(slides, '7557-3')]},
  ]
}

export const LayersIcons = layers;

function getValueRange(ranges, value) {
  var i;
  for (i = 0; i < ranges.length; i++) {
    if (value >= ranges[i][0] && value < ranges[i][1]) {
      return i;
    }
  }
  return 0;
}

function getMapCenter() {
  const hostName = window.location.hostname === 'localhost' ? window.location.search : window.location.hostname;
  const isMobile = window.innerWidth <= 768;
  let mapCenter = [47.509500, -3.119400];
  if (hostName.indexOf('windloireatlantique') !== -1) {
    mapCenter = isMobile ? [47.2, -2.3] : [47.2, -2.55];
  } else if (hostName.indexOf('windcornouaille') !== -1) {
    mapCenter = [47.9, -4.262117];
  }
  return mapCenter;
}

function getMapZoom() {
  const hostName = window.location.hostname === 'localhost' ? window.location.search : window.location.hostname;
  const isMobile = window.innerWidth <= 768;
  let mapZoom = 9.6;
  if (hostName.indexOf('windloireatlantique') !== -1) {
    mapZoom = isMobile ? 9.2 : 9.5;
  } else if (hostName.indexOf('windcornouaille') !== -1) {
    mapZoom = 10.0;
  }
  return mapZoom;
}

export function windColor(windSpeed) {
  var windColors = Object.values(config.windColors), speedRange = getValueRange(config.windRanges, windSpeed);
  return {bg: windColors[speedRange], text: speedRange > 8 ? '#FFFFFF' : '#154194'};
}

export function tempColor(temp) {
  var tempColors = Object.values(config.tempColors);
  return tempColors[getValueRange(config.tempRanges, temp)];
}

export function waveColor(waveHeight) {
  var waveColors = Object.values(config.waveColors);
  return waveColors[getValueRange(config.waveRanges, waveHeight)];
}

export function periodColor(period) {
  var periodColors = Object.values(config.periodColors);
  return periodColors[getValueRange(config.periodRanges, period)];
}

export function rainColor(rainHeight) {
  var rainColors = Object.values(config.rainColors);
  return rainColors[getValueRange(config.rainRanges, rainHeight)];
}

function assetPath(assets, key) {
  return assets[key] ? Object.values(assets[key])[0] : '';
}

export function windColorsGradient(windData) {
  let minSpeed = windData.s_min, maxSpeed = windData.s_max, speedRange = maxSpeed - minSpeed;
  let minRange = getValueRange(config.windRanges, minSpeed), maxRange = getValueRange(config.windRanges, maxSpeed);
  let windColors = Object.values(config.windColors), colorStops = [], r;
  for (r = minRange; r <= maxRange; r++) {
    let stop = Math.round((config.windRanges[r][0] - config.windRanges[minRange][0]) * 10) / (speedRange * 10);
    if (stop > 1) {
      stop = 1;
    }
    colorStops.push({stop: (r === minRange ? 0 : stop ), color: windColors[r]});
  }
  console.debug('windColorsGradient : ' + JSON.stringify(colorStops));
  return colorStops;
}

export function singleColorGradient(color) {
  return [{stop: 0, color: color}, {stop: 1, color: color}];
}

import * as d3 from "d3-selection";
import {json} from "d3-fetch";
import {transition} from "d3-transition";
import dayjs from "dayjs/esm";
import MicroModal from 'micromodal';
import {getAsync} from "./utils";
import {layout, timeline, layers, map, data, logPageView} from "./common";
import {
  config,
  waveSensorRef,
  webcamsRefs,
  harborsRefs,
  sensorsRefs,
  tempSensorsRefs,
  windColor,
  windColorsGradient, loadWebcams, loadHarbors, waveColor, singleColorGradient, waterTempSensorsRefs
} from "./config";
import {
  projectInMap,
  displayCoastLine,
  displayMapRef, clearBgLayers, toggleMap
} from "./map";
import {appendToTimeline, defaultActiveStep} from "./timeline";
import {computeNebIcon} from "./meteogramme";
import navIcons from "../img/nav/*.svg";
import weatherIcons from "../img/weather/*.svg";
import {ImageOverlay} from "leaflet/src/layer/ImageOverlay";
import router from "./router";
import * as L from "leaflet/dist/leaflet";

function loadSensorsData() {
  console.time('loadSensorsData');
  getAsync("https://static.windmorbihan.com/mesures/getlastalljson.json", function (dt) {
    // var today = new Date(new Date().toDateString()).getTime() / 1000;
    // if (!timeline.timelineReference || timeline.timelineReference === today) {
      data.windData = dt;
      if (!timeline.timelineActiveStep || timeline.timelineActiveStep === defaultActiveStep()) {
        renderWindSensors(dt);
      }
      if (timeline.timelineSteps) {
        appendToTimeline(dt);
      }
    console.timeEnd('loadSensorsData');
    console.timeEnd('displayMeasures');
    // }
  });
}

export function displayWindForecasts(timestamp) {
  var emptyForecastData = false;

  if (map.activeMap === 'wm') {
    clearBgLayers();
  }

  return fetch(config.backEndUrl + "/live_forecasts/raster.json?metric=speed" + (timestamp ? ("&timestamp=" + timestamp) : ""))
    .then(function (resp) {
      return resp.json();
    })
    .then(function (rasterData) {
      if (rasterData.width) {
        renderForecastInfo(rasterData.source, rasterData.grid, rasterData.run_timestamp);
        if (map.activeMap === 'wm' ||  map.activeMap === 'elevation') {
          return displayCoastLine(layers.dataLayers.indexOf('waves_fc') !== -1).then(function () {
            if (layers.forecastsLayer && layers.forecastsLayer.overlay && (layers.dataLayers.indexOf('waves_fc') === -1 && layers.dataLayers.indexOf('wind_fc') !== -1)) {
              map.instance.removeLayer(layers.forecastsLayer.overlay);
            }

            if (layers.dataLayers.indexOf('waves_fc') === -1 && layers.dataLayers.indexOf('wind_fc') !== -1) {
              // Note : bounds adjusted to compensate img distortion
              layers.forecastsLayer = {
                forecastId: rasterData.id,
                forecastTimestamp: timestamp,
                overlay :  new ImageOverlay(rasterData.raster, [[49, -5], [45.96, -1.05]],
                  {className: 'fc_colors', pane: 'tilePane'}).addTo(map.instance)
              };
            }
            document.querySelector("#map_container").classList.add('rendered');
          });
        } else if (map.activeMap === 'satellite') {
          if (layers.forecastsLayer && layers.forecastsLayer.overlay && (layers.dataLayers.indexOf('waves_fc') === -1 && layers.dataLayers.indexOf('wind_fc') !== -1)) {
            map.instance.removeLayer(layers.forecastsLayer.overlay);
          }
            layers.forecastsLayer = {
              forecastId: rasterData.id,
              forecastTimestamp: timestamp
            };
            document.querySelector("#map_container").classList.add('rendered');
        } else {
          return Promise.resolve();
        }
      } else {
        emptyForecastData = true;
        renderForecastInfo(null);
        if (layers.forecastsLayer && layers.forecastsLayer.overlay) {
          map.instance.removeLayer(layers.forecastsLayer.overlay);
        }
        document.querySelector("#map_container").classList.remove('rendered');
        return displayMapRef(map.activeMap, emptyForecastData);
      }
    }).then(function () {
      if (!emptyForecastData) {
        initWindParticles();
        return fetch(config.backEndUrl + "/live_forecasts/raster.json?metric=direction" + (timestamp ? ("&timestamp=" + timestamp) : ""))
          .then(function (resp) {
            return resp.json();
          })
          .then(function (rasterData) {
            if (rasterData.width) {
              map.rasterWidth = rasterData.width;
              map.rasterHeight = rasterData.height;
              map.windLayer.start();
              if (map.activeMap === 'satellite') {
                map.windLayer.loadColorGradient(windColorsGradient(rasterData));
              } else {
                map.windLayer.loadColorGradient(singleColorGradient('#FFFFFF'));
              }
              map.windLayer.setWindData({
                uMin: rasterData.u_min,
                uMax: rasterData.u_max,
                vMin: rasterData.v_min,
                vMax: rasterData.v_max,

                width: rasterData.width,
                height: rasterData.height,

                image: rasterData.path
              });
              updateParticlesBBox();
            }
          });
      }
    });
}

// Note - WindMap expects the bottomLeft rect corner relative position from the bottomLeft raster corner
export function updateParticlesBBox(particlesLayer) {
  let scale = map.instance.getZoomScale(map.instance.getZoom(), map.refZoom),
    bottomLeft = map.instance.project(map.instance.getBounds().getSouthWest(), map.refZoom);
  // console.log('scale : ' + scale + ' - bottomLeft : ' + bottomLeft);
  let mSize = map.instance.getSize();
  let nWidth = (mSize.x / map.windWidth) / scale;
  let nHeight = (mSize.y / map.windHeight) / scale;
  let x, y, rect;
  // console.log('relative rect : ' + JSON.stringify(rect));
  // console.log('actual rect : ' + JSON.stringify({
  //   width: Math.round(rect.width * map.rasterWidth), height: Math.round(rect.height * map.rasterHeight),
  //   x: Math.round(rect.x * map.rasterWidth), y: Math.round(rect.y * map.rasterHeight)
  // }));
  if (map.windLayer && layers.dataLayers.indexOf('wind_fc') !== -1) {
    x = Math.abs(bottomLeft.x - map.windBox[0].x) / map.windWidth;
    y = Math.abs(bottomLeft.y - map.windBox[1].y) / map.windHeight;
    rect = {width: nWidth, height: nHeight, x: x, y: y};
    map.windLayer.setWindMapRect(rect.x, rect.y, rect.width, rect.height);
    map.windLayer.start();
  }
  if (map.wavesLayer && layers.dataLayers.indexOf('waves_fc') !== -1) {
    x = Math.abs(bottomLeft.x - map.wavesBox[0].x) / map.wavesWidth;
    y = Math.abs(bottomLeft.y - map.wavesBox[1].y) / map.wavesHeight;
    rect = {width: nWidth, height: nHeight, x: x, y: y};
    map.wavesLayer.setWindMapRect(rect.x, rect.y, rect.width, rect.height);
    map.wavesLayer.start();
  }
}

function onWebGLFailure() {
  console.error("WebGL Context lost");
}

function initWindParticles() {
  if (!map.windLayer) {
    let params = {
      container: document.getElementById("particles_wrapper"),
      webGLFailureCallback: onWebGLFailure
    };
    map.windLayer = new WindMap(params);
    map.windLayer.opacity = 1;
    map.windLayer.fadeOut = 0.88;
    map.windLayer.thickness = 1.5;
    map.windLayer.speedFactor = 1.5;
    map.windLayer.particlesNumber = 650;
    map.windLayer.dropRate = 0.05;
  }
}

function initWavesParticles() {
  if (!map.wavesLayer) {
    let params = {
      container: document.getElementById("waves_wrapper"),
      webGLFailureCallback: onWebGLFailure
    };
    map.wavesLayer = new WindMap(params);
    map.wavesLayer.opacity = 0.8;
    map.wavesLayer.fadeOut = 0.999;
    map.wavesLayer.thickness = 2;
    map.wavesLayer.speedFactor = 0.5;
    map.wavesLayer.particlesNumber = 2000;
    map.wavesLayer.dropRate = 0.2;
  }
}

function renderForecastInfo(source, grid, runTs) {
  let dataInfo = document.querySelector("#data_info"), fcInfo = dataInfo.querySelector("#fc_info");
  if (source) {
    let sourceDetail = "", runTimestamp = /^\d+$/.test(runTs) ? dayjs.unix(runTs) : dayjs(runTs);
    if (source === "arome" || source === 'arpege') {
      sourceDetail = "Météo-France (" + source.toUpperCase() + " " + grid + ")";
    } else {
      sourceDetail = source.toUpperCase();
    }
    if (sourceDetail === "WW3") {
      sourceDetail = "WW3 Météo-France (haute résolution)";
    }
    let fcContent = '<p>Prévis. du ' + runTimestamp.format("DD/MM à H[h]") + '<br/><em>' + sourceDetail + '</em></p>';
    if (fcInfo) {
      fcInfo.innerHTML = fcContent;
    } else {
      console.log('sourceDetail: ' + fcContent);
      dataInfo.insertAdjacentHTML("beforeend", '<div id="fc_info">' + fcContent + '</div>');
    }
  } else {
    let emptyContent = "<p>Données indisponibles</p>";
    if (fcInfo) {
      fcInfo.innerHTML = emptyContent;
    } else {
      dataInfo.insertAdjacentHTML("beforeend", emptyContent);
    }
  }
}

function renderWindInfo() {
  let dataInfo = document.querySelector("#data_info"), windInfo = dataInfo.querySelector("#wind_info"),
    windContent = '<p>Vents réels (et rafales) en nœuds</p>';
  if (!windInfo) {
    dataInfo.insertAdjacentHTML("beforeend", '<div id="wind_info">' + windContent + '</div>');
  }
}

function renderAmpInfo() {
  let dataInfo = document.querySelector("#data_info"), ampInfo = dataInfo.querySelector("#amp_info"),
    ampContent = '<p>Données Office français de la biodiversité</p>';
  if (!ampInfo) {
    dataInfo.insertAdjacentHTML("beforeend", '<div id="amp_info">' + ampContent + '</div>');
  }
}

export function hideWindForecasts() {
  if (layers.forecastsLayer && layers.forecastsLayer.overlay) {
    map.instance.removeLayer(layers.forecastsLayer.overlay);
  }
  layers.forecastsLayer = null;
  if (map.windLayer) {
    map.windLayer.stop();
  }
  if (document.querySelector("#fc_info")) {
    document.querySelector("#fc_info").innerHTML = "";
  }
  displayMapRef(map.activeMap);
}

export function displayWavesForecasts(timestamp) {
  if (map.activeMap === 'wm') {
    clearBgLayers();
  }
  return fetch(config.backEndUrl + "/live_forecasts/raster.json?source=ww3&metric=wave" + (timestamp ? ("&timestamp=" + timestamp) : ""))
    .then(function (resp) {
      if (resp.ok) {
        return resp.json();
      } else {
        throw new Error("Forecast data not found");
      }
    })
    .then(function (rasterData) {
      // console.log("rasterData: " + JSON.stringify(rasterData));
      if (rasterData.width) {
        renderForecastInfo(rasterData.source, rasterData.grid, rasterData.run_timestamp);
        // var wmLayerBtn = document.querySelector("#styled_bg");
        // if (!wmLayerBtn.classList.contains("active") && map.activeMap !== 'elevation') {
        //   toggleMap(wmLayerBtn, "wm");
        // }
        return displayCoastLine(true, true).then(function () {
          if (layers.forecastsLayer && layers.forecastsLayer.overlay) {
            map.instance.removeLayer(layers.forecastsLayer.overlay);
          }
          // Note : bounds adjusted to compensate img distortion
          // Improvement : reproject properly to avoid distortion in the whole area
          layers.forecastsLayer = {
            forecastId: rasterData.id,
            forecastTimestamp: timestamp,
            overlay: new ImageOverlay(rasterData.raster, [[49, -5], [45.96, -0.98]],
              {className: 'fc_colors', pane: 'tilePane'}).addTo(map.instance)
          };
          if (!document.querySelector("#waves_wrapper")) {
            document.querySelector("#map .leaflet-tile-pane")
              .insertAdjacentHTML('beforeend', '<div id="waves_wrapper" class="leaflet-zoom-animated" style="width: ' + layout.width + 'px; height: ' + layout.height + 'px;"></div>');
          }
          document.querySelector("#map_container").classList.add('rendered');
        });
      } else {
        renderForecastInfo(null);
        if (layers.forecastsLayer && layers.forecastsLayer.overlay) {
          map.instance.removeLayer(layers.forecastsLayer.overlay);
        }
        document.querySelector("#map_container").classList.remove('rendered');
        return displayMapRef(map.activeMap);
      }
    }).then(function () {
      initWavesParticles();
      return fetch(config.backEndUrl + "/live_forecasts/raster.json?metric=wave_direction&source=ww3" + (timestamp ? ("&timestamp=" + timestamp) : ""))
        .then(function (resp) {
          return resp.json();
        })
        .then(function (rasterData) {
          if (rasterData.width) {
            map.rasterWidth = rasterData.width;
            map.rasterHeight = rasterData.height;
            map.wavesLayer.start();
            map.wavesLayer.loadColorGradient(singleColorGradient('#02ACF1'));
            map.wavesLayer.setWindData({
              uMin: rasterData.u_min,
              uMax: rasterData.u_max,
              vMin: rasterData.v_min,
              vMax: rasterData.v_max,

              width: rasterData.width,
              height: rasterData.height,

              image: rasterData.path
            });
            if (layers.dataLayers.indexOf('wind_fc') === -1) {
              updateParticlesBBox();
            }
          }
        });
    }).catch(function(err) {
      console.warn("Could not retrieve waves forecast data for provided timestamp");
      hideWavesForecasts(true);
    });
}

export function hideWavesForecasts(skipRefresh = false) {
  if (layers.forecastsLayer) {
    map.instance.removeLayer(layers.forecastsLayer.overlay);
  }
  layers.forecastsLayer = null;
  if (map.wavesLayer) {
    map.wavesLayer.stop();
  }
  if (document.querySelector("#fc_info")) {
    document.querySelector("#fc_info").innerHTML = "";
  }
  displayMapRef(map.activeMap, skipRefresh);
}

export function initMeasuresLayer() {
  layers.overlay.node().innerHTML =
    '<symbol id="arrow" viewBox="0 0 43.4 47.4" width="24px" height="26px">' +
    '<path stroke-width="2" d="M3.3,3.6C3.7,3.2,4.4,3,5.1,3.2l16.7,5.4l16.6-5.5c0.7-0.2,1.4,0,1.8,0.5c0.5,0.5,0.6,1.3,0.3,1.9L23.5,43.4c-0.2,0.6-0.9,1-1.6,1c-0.7,0-1.3-0.4-1.6-1L2.9,5.6C2.7,5,2.8,4.2,3.3,3.6C3.2,3.7,3.2,3.7,3.3,3.6z"/>' +
    '</symbol>' +
    '<symbol id="alt_arrow" width="22px" height="24px" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<g id="group" transform="rotate(180) translate(-23, -24)">' +
        '<g id="Subtract">' +
          '<mask id="path-1-outside-1_31_8077" maskUnits="userSpaceOnUse" x="1.5" y="0" width="21" height="23" fill="black">' +
            '<rect fill="white" x="1.5" width="21" height="23"/>' +
            '<path fill-rule="evenodd" clip-rule="evenodd" d="M20.3476 21.9103C20.6989 22.0119 21.0501 21.9103 21.2508 21.7073C21.5017 21.4028 21.5519 20.9968 21.4515 20.6923L18.7075 14.6627L11.9791 12.0841L5.25738 14.7161L2.58468 20.743C2.43414 21.0475 2.48432 21.4536 2.73521 21.7073C2.93592 21.9611 3.28716 22.0626 3.63841 21.9611L11.9679 19.1697L20.3476 21.9103ZM6.41003 12.1169L11.6106 10.0806L11.9713 9.93931L12.333 10.0779L17.5268 12.0684L12.7206 1.50753C12.5701 1.20301 12.269 1 11.9177 1C11.5665 1 11.2153 1.20301 11.1149 1.50753L6.41003 12.1169Z"/>' +
          '</mask>' +
          '<path fill-rule="evenodd" clip-rule="evenodd" d="M20.3476 21.9103C20.6989 22.0119 21.0501 21.9103 21.2508 21.7073C21.5017 21.4028 21.5519 20.9968 21.4515 20.6923L18.7075 14.6627L11.9791 12.0841L5.25738 14.7161L2.58468 20.743C2.43414 21.0475 2.48432 21.4536 2.73521 21.7073C2.93592 21.9611 3.28716 22.0626 3.63841 21.9611L11.9679 19.1697L20.3476 21.9103ZM6.41003 12.1169L11.6106 10.0806L11.9713 9.93931L12.333 10.0779L17.5268 12.0684L12.7206 1.50753C12.5701 1.20301 12.269 1 11.9177 1C11.5665 1 11.2153 1.20301 11.1149 1.50753L6.41003 12.1169Z" fill="white"/>' +
        '</g>' +
      '</g>' +
    '</symbol>' +
    '<symbol id="wave_arrow" viewBox="0 0 100 100" width="24px" height="26px">' +
      '<g transform="translate(0,-952.36218)">' +
        '<path d="m 49.999995,1037.3622 27.00001,-38.00002 -17,3.00002 4,-35.00002 -28.00001,0 4,35.00002 -17,-3.00002 z" stroke-width="2"/>' +
      '</g>' +
    '</symbol>' +
    '<g class="zoom_wrapper"></g>';
}

export function initForecastsLayer() {
  layers.forecastsLayer.node().innerHTML =
    '<symbol id="wave_arrow" viewBox="0 0 100 100" width="16px">' +
    '<g transform="translate(0,-952.36218)">' +
    '<path d="m 49.999995,1037.3622 27.00001,-38.00002 -17,3.00002 4,-35.00002 -28.00001,0 4,35.00002 -17,-3.00002 z" fill="none" stroke="#0c4da2"/>' +
    '</g>' +
    '</symbol>' +
    '<g class="zoom_wrapper"></g>';
}

export function displayMeasures() {
  loadSensorsData();
  if (!data.sensorsPolling) {
    data.sensorsPolling = setInterval(loadSensorsData, 30000);
  }
}

export function hideMeasures() {
  layers.overlay.selectAll(".wind_sensor").remove();
}

export function displayPOIs(timestamp) {
  getAsync(config.backEndUrl + "/live_forecasts/weather.json" + (timestamp ? ("?timestamp=" + timestamp) : ""), function (poisData) {
    layers.overlay.select(".zoom_wrapper").selectAll(".weather_poi").remove();
    var pois = layers.overlay.select(".zoom_wrapper").selectAll(".weather_poi")
      .data(poisData.pois, function (d) {
        return d.name;
      }).join("g")
      .attr("class", function (d) {return router.lastResolved()[0].data && d.slug ===  router.lastResolved()[0].data.name ? "weather_poi poi-active" : "weather_poi";})
      .attr("id", function (d) {return "poi_" + d.slug;})
      .attr("transform", function (d) {return "translate(" + projectInMap(d.lng, d.lat) + ")";});
    renderForecastInfo(poisData.pois[0].source, poisData.pois[0].grid, poisData.pois[0].run_ts);
    pois.append("foreignObject").attr("width", 80).attr("height", 64).attr("transform", "translate(-40, -36)")
      .append("xhtml:body")
      .html(function (d, j) {
        let poiData = Object.values(poisData.pois)[j];
        if (poiData) {
          let currentRain = (poiData.tw || poiData.tp || 0), prevRain = (poiData.prev_tw || poiData.prev_tp || 0);
          let icon = computeNebIcon(poiData.hc, poiData.mc, poiData.lc, parseInt(poisData.timestamp), currentRain - prevRain);
          return "<a href='/previsions/" + d.slug + "' class='poi_icon' data-navigo><img src='" + weatherIcons[icon] + "' alt='" + d.name + "'/><span>" + poiData.temp + "°</span></a>" +
            "<p class='poi_name'>" + d.name + "</p>";
        }
      });
    router.updatePageLinks();
  });
}

export function hidePOIs() {
  layers.overlay.selectAll(".weather_poi").remove();
}

export function displayWaves() {
  if (waveSensorRef && (!timeline.timelineActiveStep || timeline.timelineActiveStep === defaultActiveStep())) {
    getAsync("https://static.windmorbihan.com/mesures/getmapelement-waves.html", function (html) {

      var parser = new DOMParser(), tempDoc = parser.parseFromString(html.replace(/className/g, 'class'), "text/html");

      let waveSensors = layers.overlay.select(".zoom_wrapper").selectAll(".wave_sensor").data([waveSensorRef])
        .join("g").attr("class", "wave_sensor").attr("id", function (d) {return "sensor_" + d.nid;})
        .attr("transform", function (d) {
          return "translate(" + projectInMap(d.lng, d.lat) + ")";
        });

      if (tempDoc.querySelector(".direction")) {
        waveSensors.append("use").attr("xlink:href", "#wave_arrow")
          .attr("x", "-18").attr("y", "-19").attr("width", "36px").attr("height", "39px")
          .attr("fill", "#FFF")
          .attr("transform", function (d) {
            let swellDir = tempDoc.querySelector('[nid="' + d.nid + '"] .direction img')
              .getAttribute("direction");
            return "rotate(" + swellDir + ", 0, 0)";
          });
        waveSensors.append("g").attr("class", "wave_details").attr("transform", "translate(-140, -16)")
          .append("foreignObject")
          .attr("width", 130).attr("height", 36)
          .append("xhtml:body")
          .append("div").attr("class", "swell_data")
          .html(function (d) {
            return Array.from(tempDoc.querySelectorAll('[nid="' + d.nid + '"] .direction > a > div'))
              .map(function (elt) {
                return elt.outerHTML
              }).join('');
          });
      } else {
        waveSensors.append("foreignObject").attr("width", 32).attr("height", 32).attr("transform", "translate(-16, -16)")
          .append("xhtml:body")
          .html(function (d) {
            return '<a href="/' + d.slug + '" title="' + d.label + '" data-navigo><img src="' + navIcons['waves'] + '" alt="' + d.label + '"></a>';
          });
      }
      waveSensors.on("click", function(evt) {
        evt.stopPropagation();
        router.navigate("/" + waveSensorRef.slug);
      });
      waveSensors.on("mouseover", function (evt, d) {
        d3.select(this).raise();
        d3.select("#sensor_" + d.nid).append("text").attr("class", "sensor_name").text(waveSensorRef.label);
      }).on("mouseout", function (evt, d) {
        d3.selectAll(".sensor_name").remove();
        d3.select(this).lower();
      });
      console.timeEnd('displayWaves');
      router.updatePageLinks();
    }, true);
  }
}

export function hideWaves() {
  layers.overlay.selectAll(".wave_sensor").remove();
}

export function displayWaterTemp() {
  getAsync("https://static.windmorbihan.com/mesures/getmapelement-water_temp.html", function (html) {
    var parser = new DOMParser(), tempDoc = parser.parseFromString(html, "text/html");

    var waterTempSensors = layers.overlay.select(".zoom_wrapper").selectAll(".water_temp_sensor").data(waterTempSensorsRefs).join("g")
      .attr("class", "water_temp_sensor").attr("id", function (d) {return "sensor_" + d.nid;})
      .attr("transform", function (d) {
        return "translate(" + projectInMap(d.lng, d.lat) + ")";
      });

    waterTempSensors.filter(function(d) { return tempDoc.querySelector('[data-nid="' + d.nid + '"]'); })
      .append("foreignObject").attr("width", 30).attr("height", 30).attr("transform", "translate(64, 18)")
      .append("xhtml:body")
      .html(function (d) {
        return "<p>" + tempDoc.querySelector('[data-nid="' + d.nid + '"]').textContent.replace(/\.\d+/, '').replace(/\s+C/, '') + "</p>";
      });

    waterTempSensors.on("click", function(evt, d) {
      evt.stopPropagation();
      router.navigate("/" + waterTempSensorsRefs[0].slug);
    });
    waterTempSensors.on("mouseover", function (evt, d) {
      d3.select(this).raise();
      d3.select("#sensor_" + d.nid).append("text").attr("class", "sensor_name").text(waterTempSensorsRefs[0].label);
    }).on("mouseout", function (evt, d) {
      d3.selectAll(".sensor_name").remove();
      d3.select(this).lower();
    });
    console.timeEnd('displayWaterTemp');
  }, true);
}

export function hideWaterTemp() {
  layers.overlay.selectAll(".water_temp_sensor").remove();
}

export function displayWebcams() {
  loadWebcams(function() {
    var webcams = layers.overlay.select(".zoom_wrapper").selectAll(".webcam_spot").data(webcamsRefs).join("g").attr("class", "webcam_spot")
      .attr("transform", function (d) {
        return "translate(" + projectInMap(d.lng, d.lat) + ")";
      });

    webcams.append("foreignObject").attr("width", 32).attr("height", 32).attr("transform", "translate(-16, -16)")
      .append("xhtml:body")
      .html(function (d) {
        return '<a href="/webcam/' + d.slug + '" title="' + d.label + '" data-navigo><img src="' + navIcons['webcam-map'] + '" alt="' + d.label + '"></a>';
      });
    router.updatePageLinks();
  });
}

export function hideWebcams() {
  layers.overlay.selectAll(".webcam_spot").remove();
}

export function displayTemperatures() {
  getAsync("https://static.windmorbihan.com/mesures/getmapelement-temp.html", function (html) {
    var parser = new DOMParser(), tempDoc = parser.parseFromString(html, "text/html");

    var tempSensors = layers.overlay.select(".zoom_wrapper").selectAll(".temp_sensor").data(tempSensorsRefs).join("g").attr("class", "temp_sensor")
      .attr("transform", function (d) {
        return "translate(" + projectInMap(d.lng, d.lat) + ")";
      });

    tempSensors.filter(function(d) { return tempDoc.querySelector('.box_capteur[nid="' + d.nid + '"]'); })
      .append("foreignObject").attr("width", 36).attr("height", 30).attr("transform", "translate(-50, -12)")
      .append("xhtml:body")
      .html(function (d) {
        return "<p>" + tempDoc.querySelector('.box_capteur[nid="' + d.nid + '"] .temp_value span').textContent + "</p>";
      });
  }, true);
}

export function hideTemperatures() {
  layers.overlay.selectAll(".temp_sensor").remove();
}

export function displayLegend() {
  // var windLegendItems = d3.selectAll("#wind_legend tbody").selectAll("tr").data(config.windLegendRanges).join("tr")
  //   .attr("style", function (d) {
  //     return "background-color: " + d.color;
  //   });
  //
  // windLegendItems.append("td").text(function (d) {
  //   return d.bLabel;
  // });
  // windLegendItems.append("td").text(function (d) {
  //   return d.kLabel;
  // });
  // windLegendItems.append("td").text(function (d) {
  //   return d.kmLabel;
  // });
  //
  // var wavesLegendItems = d3.selectAll("#waves_legend tbody").selectAll("tr").data(config.wavesLegendRanges).join("tr")
  //   .attr("style", function (d) {
  //     return "background-color: " + d.color;
  //   });
  //
  // wavesLegendItems.append("td").text(function (d) {
  //   return d.height;
  // });
  // wavesLegendItems.append("td").text(function (d) {
  //   return d.label;
  // });
  //
  //
  // d3.select("#map_legend").classed("active", true);
}

export function hideLegend() {
  // d3.selectAll("#wind_legend tr").remove();
  // d3.selectAll("#waves_legend tr").remove();
  // d3.select("#map_legend").classed("active", false);
}

export function displayTide() {
  loadHarbors(function() {
    var harbors = layers.overlay.select(".zoom_wrapper").selectAll(".tide_spot")
      .data(harborsRefs).join("g").attr("class", "tide_spot")
      .attr("transform", function (d) {
        return "translate(" + projectInMap(d.lng, d.lat) + ")";
      })
      .on("click", tideDetails);

    harbors.append("foreignObject").attr("width", 32).attr("height", 32).attr("transform", "translate(-16, -16)")
      .append("xhtml:body")
      .html(function (d) {
        return '<a href="/port/' + d.slug + '" title="' + d.label + '" data-navigo><img src="' + navIcons['tide-map'] + '" alt="' + d.label + '"></a>';
      });
    router.updatePageLinks();
  });
}

export function hideTide() {
  layers.overlay.selectAll(".tide_spot").remove();
}

export function hideAmp() {
  var ampZones = document.querySelectorAll(".amp_zone"), i;
  if (ampZones.length > 0) {
    for (i = 0 ; i < ampZones.length; i++){
      ampZones[i].remove();
    }
  }
  if (document.querySelector("#amp_info")) {
    document.querySelector("#amp_info").remove();
  }
}

export function hideAmpPois() {
  layers.overlay.selectAll(".amp_spot").remove()
  if (document.querySelector("#amp_info")) {
    document.querySelector("#amp_info").remove();
  }
}

export function displayAmpPois() {
  json(config.backEndUrl + "/features.json?source=amp_poi").then(function (geojsonPois) {
    renderAmpInfo();
    var ampPois = layers.overlay.select(".zoom_wrapper").selectAll(".amp_spot")
      .data(geojsonPois.features).join("g").attr("class", "amp_spot")
      .attr("transform", function (d) {
        return "translate(" + projectInMap(d.geometry.coordinates[0], d.geometry.coordinates[1]) + ")";
      })
      .on("click", ampDetails);

    ampPois.append("foreignObject").attr("width", 32).attr("height", 32)
      .append("xhtml:body")
      .html(function (d) {
        return '<div title="' + d.properties.title + '" ><img src="' + navIcons['amp-map'] + '" alt="' + d.properties.title + '"></a>';
      });
  });
}

export function displayAmp() {
  let zoneStyle = {stroke: false, fill: true, fillColor: 'rgb(224,87,247)', fillOpacity: 0.7};
  return json(config.backEndUrl + "/features.json?source=amp").then(function (geoJsonZone) {
    renderAmpInfo();
    let geoJson = L.geoJSON(geoJsonZone.features,
      {className: 'amp_zone', style: zoneStyle, pane: 'tilePane', filter: function(feature, layer) { return true; }});
    geoJson.addTo(map.instance);
    layers.bgLayers.push(geoJson);
    return geoJson;
  });
}

function ampDetails(evt, d){
  document.querySelector("#map_modal").setAttribute("data-modal", "amp");
  document.querySelector("#map_modal_title").innerHTML = d.properties.title;
  document.querySelector("#header_links").innerHTML = '<button class="modal__close" aria-label="Fermer" data-micromodal-close></button>';
  var imgMarkup = '';
  if (d.properties.img) {
    imgMarkup = "<div class='amp_photo'><img src='" + d.properties.img + "'/><p>Credit Photo : " + d.properties.credit_img1 + "</p></div>";
  }
  document.querySelector("#map_modal_content").innerHTML = "<div class='amp_modal'>" + imgMarkup +
    "<div class='amp_description'>" +
      "<h2>" + d.properties.title + "</h2>" +
      "<h3>" + d.properties.header_text + "</h3>" +
      "<h4>Lieu : " + d.properties.amp_name + "</h4>" +
      "<p>" + d.properties.description + "</p>" +
    "</div>" +
  "</div>";
  MicroModal.show('map_modal', {disableScroll: true});
}

export function webcamDetails(d) {
  document.querySelector("#map_modal").setAttribute("data-modal", "webcam");
  document.querySelector("#map_modal_title").innerHTML = d.label;
  document.querySelector("#header_links").innerHTML = '<button class="modal__close" aria-label="Fermer" data-micromodal-close></button>';
  document.querySelector("#map_modal_content").innerHTML = d.content;
  MicroModal.show('map_modal', {disableScroll: true, onClose: () => { router.navigate("/"); }});
}

export function tideDetails(d) {
  document.querySelector("#map_modal").setAttribute("data-modal", "tide");
  document.querySelector("#map_modal_title").innerHTML = d.label;
  document.querySelector("#header_links").innerHTML = '<button class="modal__close" aria-label="Fermer" data-micromodal-close></button>';
  document.querySelector("#map_modal_content").innerHTML = '<iframe id="shom_wrapper" src="javascript:void(0);"></iframe>';
  var frm = document.querySelector("#map_modal_content #shom_wrapper");
  frm.srcdoc = '<html><body style="margin: 0; padding: 0;"><script src="' + d.shomUrl + '"></script></body></html>';
  MicroModal.show('map_modal', {disableScroll: true, onClose: () => { router.navigate("/"); }});
}

export function displayForecastDetails(popup, lat, lng) {
  getForecastPoint(layers.forecastsLayer.forecastId, lat, lng, function (pointData) {
    if (pointData.lat) {
      map.currentFocus = pointData;
      let color = map.currentFocus.ws ? windColor(map.currentFocus.ws) : {bg: waveColor(map.currentFocus.wah), text: '#154194'};
      let value = map.currentFocus.ws ? (Math.round(map.currentFocus.ws) + 'nds') : (map.currentFocus.wah + 'm');
      let windDir = '';
      if (map.currentFocus.wind_d) {
        let dirVal = (270 - map.currentFocus.wind_d) % 360;
        dirVal = (dirVal < 10) ? ('00' + dirVal) : (dirVal < 100 ? ('0' + dirVal) : dirVal);
        windDir = ' / ' + dirVal + '°';
      }
      let waveDir = map.currentFocus.wad ? ('<img src="' + navIcons['fleche-vague'] + '" alt="direction" class="pointer_direction" style="transform: rotate(' + (map.currentFocus.wad - 180) + 'deg); transform-origin: 50% 50% 0px; display: inline;"/>') : '';
      popup.setContent('<p style="background-color: ' + color.bg + '; color: ' + color.text + '" class="pointer_info">' + value + (windDir || waveDir) + '</p>');
    } else {
      popup.setContent(lat + ', ' + lng);
    }
  });
}

function getForecastPoint(forecastId, lat, lng, callback) {
  getAsync(config.backEndUrl + "/live_forecasts/" + forecastId + "/point?lat=" + lat + "&lng=" + lng, function (pointData) {
    callback(pointData);
  });
}

export function geolocateMe() {
  map.instance.locate();
}

export function renderWindSensors(data) {
  if (layers.dataLayers.indexOf('wind') !== -1) {
    renderWindInfo();
    let availableSensors = data.filter(function (d) {
      return Object.keys(sensorsRefs).indexOf(d.nid.toString()) !== -1;
    });
    layers.overlay.select(".zoom_wrapper").selectAll(".wind_sensor").data(availableSensors, function (d) {
      return d.nid;
    })
      .join(
        function (enter) {
          var sensors = enter.append("g").attr("class", "wind_sensor").attr("id", function (d) {
            return "sensor_" + d.nid;
          })
            .attr("transform", function (d) {
              return "translate(" + projectInMap(sensorsRefs[d.nid].lng, sensorsRefs[d.nid].lat) + ")";
            });
          sensors.append("use").attr("xlink:href", "#arrow").attr("x", "-12").attr("y", "-13")
            .attr("width", "24px").attr("height", "26px").attr("class", function (d) {
            return isValidAngle(d.nid, d.wind_dir_true) ? "" : "devent";
          })
            .attr("transform", function (d) {
              return "rotate(" + d.wind_dir_true + ", 0, 0)";
            });
          sensors.append("g").attr("class", "wind_details").attr("transform", "translate(16, -12)")
            .append("foreignObject")
            .attr("width", 60).attr("height", 30)
            .append("xhtml:body")
            .html(function (d) {
              let color = windColor(d.wind_pow_knot), colorMax = windColor(d.wind_pow_knot_max);
              return "<p>" +
                "<span style='background-color: " + color.bg + "; color: " + color.text + "'>" + d.wind_pow_knot + "</span>" +
                "<span style='background-color: " + colorMax.bg + "; color: " + colorMax.text + "'>(" + d.wind_pow_knot_max + ")</span>" +
                "</p>"
            });
          sensors.on("click", function(evt, d) {
              evt.stopPropagation();
              router.navigate("/" + sensorsRefs[d.nid].slug);
            });
          sensors.on("mouseover", function (evt, d) {
            d3.select(this).raise();
            d3.select("#sensor_" + d.nid).append("text").attr("class", "sensor_name").text(sensorsRefs[d.nid].label);
          }).on("mouseout", function (evt, d) {
            d3.selectAll(".sensor_name").remove();
            d3.select(this).lower();
          });
          return sensors;
        },
        function (update) {
          update.select("use")
            .attr("transform", function (d) {
              return "rotate(" + d.wind_dir_true + ", 0, 0)";
            });
          update.select("g foreignObject body p span:first-child").transition()
            .style("background-color", function (d) {
              return windColor(d.wind_pow_knot).bg;
            })
            .style("color", function (d) {
              return windColor(d.wind_pow_knot).text;
            })
            .text(function (d) {
              return d.wind_pow_knot;
            });
          update.select("g foreignObject body p span:last-child").transition()
            .style("background-color", function (d) {
              return windColor(d.wind_pow_knot_max).bg;
            })
            .style("color", function (d) {
              return windColor(d.wind_pow_knot_max).text;
            })
            .text(function (d) {
              return '(' + d.wind_pow_knot_max + ')';
            });
          return update;
        }
      );
  }
}

export function updatePositions() {
  layers.overlay.selectAll(".wind_sensor")
    .attr("transform", function (d) {
      return "translate(" + projectInMap(sensorsRefs[d.nid].lng, sensorsRefs[d.nid].lat) + ")";
    });
  layers.overlay.selectAll(".weather_poi")
    .attr("transform", function (d) {
      return "translate(" + projectInMap(d.lng, d.lat) + ")";
    });
  layers.overlay.selectAll(".amp_spot")
    .attr("transform", function (d) {
      return "translate(" + projectInMap(d.geometry.coordinates[0], d.geometry.coordinates[1]) + ")";
    });
  layers.overlay.selectAll(".wave_sensor, .webcam_spot, .temp_sensor, .water_temp_sensor, .tide_spot")
    .attr("transform", function (d) {
      return "translate(" + projectInMap(d.lng, d.lat) + ")";
    });
}

export function toggleLayer(btn, layerRef) {
  var mapContainer = document.querySelector("#map_container");
  if (layers.dataLayers.indexOf(layerRef) !== -1) {
    removeLayer(layerRef);
    if (layerRef === "wind") {
      var layerInfoContainer = document.querySelector("#wind_info");
      if (layerInfoContainer) {
        layerInfoContainer.remove();
      }
    }
    if (layerRef === "wave_fc" && map.activeMap === 'wm') {
      displayCoastLine(true)
    }
    layers.dataLayers.splice(layers.dataLayers.indexOf(layerRef), 1);
    mapContainer.classList.remove(layerRef);
  } else {
    addLayer(layerRef);
    layers.dataLayers.push(layerRef);
    mapContainer.classList.add(layerRef);
  }
  let btnElt = btn.classList.contains('layer_preview') ? document.querySelector(`#map_layers #${layerRef}`) : btn;
  const sideBtn = document.querySelector(`#${layerRef}_side`);

  if (layerRef === "legend") {
    btnElt = document.querySelector("#legend");
  }
  if (btnElt) {
    btnElt.classList.toggle('active');
  }
  mapContainer.classList.remove("hide_inactive");
  if (sideBtn) {
    sideBtn.classList.toggle('active');
  }
  logPageView(router.lastResolved(), '/map/' + layerRef, layerRef);
}

function isValidAngle(sensorId, windDir) {
  let validAngle = sensorsRefs[sensorId].validAngle, dir = parseInt(windDir);
  // should work on ecole de voile
  if (validAngle && validAngle.filter(v => v).length > 0) {
    let fromAngle, toAngle;
    if (validAngle.length === 3) {
      fromAngle = validAngle[0];
      toAngle = validAngle[2];
    } else {
      fromAngle = validAngle[0];
      toAngle = validAngle[1];
    }
    if (fromAngle > toAngle) {
      return (dir >= fromAngle && dir <= 360) || (dir >= 0 && dir <= toAngle);
    } else {
      return dir >= fromAngle && dir <= toAngle;
    }
  }
  return true;
}

export function addLayer(layerRef) {
  console.debug('Adding layer ' + layerRef);
  switch (layerRef) {
    case 'wind_fc':
      displayWindForecasts(timeline.timelineActiveStep ? d3.select(timeline.timelineActiveStep).datum()[0] : null);
      break;
    case 'waves_fc':
      displayWavesForecasts(timeline.timelineActiveStep ? d3.select(timeline.timelineActiveStep).datum()[0] : null);
      break;
    case 'weather_fc':
      displayPOIs(timeline.timelineActiveStep ? d3.select(timeline.timelineActiveStep).datum()[0] : null);
      break;
    case 'wind':
      displayMeasures();
      break;
    case 'waves':
      if(!timeline.timelineActiveStep || (timeline.timelineActiveStep && timeline.timelineActiveStep.classList.contains("tl_obs"))){
        displayWaves();
      }
      break;
    case 'webcam':
      displayWebcams();
      break;
    case 'temp':
      if(!timeline.timelineActiveStep || (timeline.timelineActiveStep && timeline.timelineActiveStep.classList.contains("tl_obs"))){
        displayTemperatures();
      }
      break;
    case 'legend':
      displayLegend();
      break;
    case 'tide_fc':
      displayTide();
      break;
    case 'amp':
      displayAmp();
      break;
    case 'amp_poi':
      displayAmpPois();
      break;
    default:
      console.debug('Unsupported layer ref : ' + layerRef);
  }
}

function removeLayer(layerRef) {
  console.debug('Removing layer ' + layerRef);
  switch (layerRef) {
    case 'wind_fc':
      hideWindForecasts();
      break;
    case 'waves_fc':
      hideWavesForecasts();
      break;
    case 'weather_fc':
      hidePOIs();
      break;
    case 'wind':
      hideMeasures();
      break;
    case 'waves':
      hideWaves();
      break;
    case 'webcam':
      hideWebcams();
      break;
    case 'temp':
      hideTemperatures();
      break;
    case 'legend':
      hideLegend();
      break;
    case 'tide_fc':
      hideTide();
      break;
    case 'amp':
      hideAmp();
      break;
    case 'amp_poi':
      hideAmpPois();
      break;
    default:
      console.debug('Unsupported layer ref : ' + layerRef);
  }
}

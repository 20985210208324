import {debounce} from "./utils";
import {timeline, data, layers, map, layout} from "./common";
import * as d3 from "d3-selection";
import dayjs from "dayjs/esm";
import ScrollBooster from 'scrollbooster';
import {
  displayWaves,
  hideWaves,
  displayWindForecasts,
  displayWavesForecasts,
  renderWindSensors,
  displayForecastDetails, displayPOIs, hideTemperatures, displayTemperatures
} from "./data";
import {config} from "./config";

export function initTimeline(timestamp) {
  console.time('initTimeline');
  let i;
  timeline.timelineReference = timestamp - 345600;
  timeline.timelineSteps = [];
  for (i = 0; i < 1296; i++) {
    timeline.timelineSteps.push([timeline.timelineReference + (i * timeline.timelineStep), timeline.timelineReference + ((i + 1) * timeline.timelineStep)]);
  }
  return fetch(`https://static.windmorbihan.com/mesures/history.json`).then(resp => resp.json())
    .then(jsonData => {
      data.timelineData = jsonData;
      renderTimeline();
      if (!timeline.keyBoardSetUp) {
        setUpKeyboardActions();
      }
      console.timeEnd('initTimeline');
    });
}

function renderTimeline() {
  console.time('renderTimeline');
  let ts, timelineElt = document.querySelector("#timeline");
  let timelineItems = d3.select("#timeline_steps").selectAll(".tl_item").data(timeline.timelineSteps).join("div")
    .attr("class", function(step) {
      return "tl_item" + ((timeline.timelineActiveStep && d3.select(timeline.timelineActiveStep).datum()[0] === step[0]) ? ' active' : '') +
        (data.timelineData[step[0]] ? ' tl_obs' : '');
    })
    .attr("data-ts", function(step) { return step[0]; })
    .attr("data-rt", function(step) { return data.timelineData[step[0]] ? '1' : null; })
    .attr("data-fc", function(step) { return (step[0] % 3600 === 0) ? (dayjs.unix(step[0]).format("HH[h]")) : null; })
    .attr("data-day", function(step) { return (new Date(step[0] * 1000).toLocaleString().indexOf('00:00:00') !== -1) ? (dayjs.unix(step[0]).format("ddd DD/MM")) : null; })
    .on("click", function(evt) {
      if (!timeline.moving) {
        const clickedTs = evt.target.getAttribute('data-ts');
        updateTimelinePosition(clickedTs);
      }
    });
  const now = new Date().getTime() / 1000, stepsCount = 1296.0;

  updateTimelineDatetime(now, false);

  if (!timeline.timelineSlider) {
    console.time('initSlider');
    timeline.timelineSlider = new ScrollBooster({
      viewport: document.querySelector("#timeline_viewport"),
      content: document.querySelector("#timeline_content"),
      scrollMode: 'transform',
      direction: 'horizontal',
      emulateScroll: true,
      onUpdate: (state) => {
        timeline.moving = (timeline.scrolling && state.isMoving) || (state.dragOffset.x !== 0 && state.isMoving);
        if (!state.isMoving) {
          timeline.scrolling = false;
        }
        if (!timeline.skipUpdate) {
          const currentStep = Math.round(((state.position.x + timeline.cursorOffset) / (stepsCount * (timeline.details ? timeline.detailsStepWidth : timeline.stepWidth))) * stepsCount) - 2;
          ts = timeline.timelineSteps[currentStep][1];
          timelineElt.classList.remove('no_focus');
          setTimeout(function() {
            selectTsTimeline(ts, false);
          }, 800);
        }
      },
      onWheel: () => {
        timeline.scrolling = true;
      }
    });
    document.querySelector("#weather_details").setAttribute('style',  `padding-left: ${(computeWeatherStep(now - 25 * 3600) - 2.5) * timeline.detailsStepWidth}px`);
    console.timeEnd('initSlider');
  }
  scaleTimelineSlider(timeline.details, timeline.details);
  console.timeEnd('renderTimeline');
}

export function scaleTimelineSlider(updateMetrics, details) {
  console.time('scaleTimelineSlider');
  timeline.skipUpdate = true;
  if (updateMetrics) {
    timeline.timelineSlider.updateMetrics();
  }
  timeline.timelineActiveStep ||= defaultActiveStep();
  const currentTs = parseInt(timeline.timelineActiveStep.getAttribute('data-ts')),
    currentStep = computeStep(currentTs), xAdjustment = (details && !layout.mobile) ? 3.5 : 1;
  const xPos = details ? ((currentStep + xAdjustment) * timeline.detailsStepWidth) : ((currentStep + xAdjustment) * timeline.stepWidth);
  timeline.timelineSlider.setPosition({x: xPos - (details ? timeline.detailsCursorOffset : timeline.cursorOffset), y: 0});
  updateTimelineDatetime(currentTs, details);
  setTimeout(() => {
    timeline.skipUpdate = false;
    timeline.details = details;
  }, 2000);
  console.timeEnd('scaleTimelineSlider');
}

function parseTimelineData(timestamp, rawData) {
  let currentTs;
  for (let nid in rawData) {
    if (rawData.hasOwnProperty(nid)) {
      for (let ts in rawData[nid]) {
        if (rawData[nid].hasOwnProperty(ts)) {
          currentTs = timestamp + parseInt(ts);
          for (let s of timeline.timelineSteps) {
            if (currentTs >= s[0] && currentTs < s[1]) {
              data.timelineData[s[0]] = data.timelineData[s[0]] || {};
              data.timelineData[s[0]][nid] = {
                nid: nid,
                ts: currentTs,
                wind_dir_true: rawData[nid][ts]['wind_dir_true'],
                wind_pow_knot: rawData[nid][ts]['wind_pow_knot'],
                wind_pow_knot_max: rawData[nid][ts]['wind_pow_knot_max']
              };
            }
          }
        }
      }
    }
  }
}

export function appendToTimeline(newMeasures) {
  let i, createdAt;
  for (i = 0; i < newMeasures.length; i++) {
    createdAt = parseInt(Object.keys(newMeasures[i].created)[0]);
    for (let s of timeline.timelineSteps) {
      if (createdAt >= s[0] && createdAt < s[1]) {
        data.timelineData[s[0]] = data.timelineData[s[0]] || {}
        if (!data.timelineData[s[0]][newMeasures[i].nid]) {
          data.timelineData[s[0]][newMeasures[i].nid] = {
            nid: newMeasures[i].nid,
            ts: createdAt,
            wind_dir_true: newMeasures[i]['wind_dir_true'],
            wind_pow_knot: newMeasures[i]['wind_pow_knot'],
            wind_pow_knot_max: newMeasures[i]['wind_pow_knot_max']
          };
        }
      }
    }
  }
}

function toggleStepTimeline(elt) {
  if (elt.getAttribute('data-rt') || elt.getAttribute('data-fc') || layers.dataLayers.indexOf('wind_fc') !== -1) {
    let activeItem = document.querySelector('#timeline_steps .tl_item.active')
    if (activeItem) {
      activeItem.classList.remove('active')
    }
    elt.classList.add('active');
    timeline.timelineActiveStep = elt;
    if (elt.getAttribute('data-fc')){
      refreshDataLayers();
    }
  }
}

function updateTimelineDatetime(ts, details) {
  const hourLabel = dayjs.unix(ts).format("ddd HH[h]mm").replace('.', ''), hour = parseInt(hourLabel.split('h')[0]);
  let hideDateLabel;
  timeline.hourLabel.innerHTML = hourLabel;
  if (layout.mobile) {
    hideDateLabel = hour <= details ? 6 : 3;
  } else {
    hideDateLabel = hour <= (details ? timeline.hoursCountDetails : timeline.hoursCount);
  }
  if (hideDateLabel) {
    timeline.dateLabel.innerHTML = '';
  } else {
    timeline.dateLabel.innerHTML = dayjs.unix(ts).format("ddd DD/MM").replace('.', '');
  }
}

export function toggleTimeline(btn) {
  if (btn.classList.contains('playing') && timeline.timelineInterval) {
    btn.classList.remove('playing');
    btn.querySelector("i").classList.replace('fa-pause', 'fa-play');
    clearInterval(timeline.timelineInterval);
  } else {
    timeline.timelineActiveStep ||= defaultActiveStep();
    btn.classList.add('playing');
    btn.querySelector("i").classList.replace('fa-play', 'fa-pause');
    timeline.timelineInterval = setInterval(function() {
      updateTimelinePosition(selectedTs() + timeline.timelineStep);
    }, 1200);
  }
}

export function defaultActiveStep() {
  return Array.from(document.querySelectorAll("#timeline_steps .tl_item.tl_obs")).pop() ||
    document.querySelector("#timeline_steps .tl_item:first-child");
}

export function nextStepTimeline() {
  console.log('nextStepTimeline');
  let offset = timeline.timelineActiveStep.nextSibling.getAttribute('data-rt') ? timeline.timelineStep :
    (3600 - parseInt(timeline.timelineActiveStep.getAttribute('data-ts')) % 3600);
  updateTimelinePosition(selectedTs() + offset);
}

export function prevStepTimeline() {
  console.log('prevStepTimeline');
  let offset = timeline.timelineActiveStep.previousSibling.getAttribute('data-rt') ? timeline.timelineStep :
    (3600 - parseInt(timeline.timelineActiveStep.getAttribute('data-ts')) % 3600);
  updateTimelinePosition(selectedTs() - offset);
}

export function nextTimeline() {
  const activeTs = parseInt(timeline.timelineActiveStep.getAttribute('data-ts')),
    nextTs = activeTs + (3600 * 24);
  updateTimelinePosition(nextTs);
}

export function prevTimeline() {
  const activeTs = parseInt(timeline.timelineActiveStep.getAttribute('data-ts')),
    nextTs = activeTs - (3600 * 24);
  updateTimelinePosition(nextTs);
}

export function refreshTimeline() {
  defaultActiveStep().click();
}

function selectedTs() {
  return d3.select(timeline.timelineActiveStep).datum()[0];
}

function selectTsTimeline(newTs, updatePosition) {
  let newItem = document.querySelector("#timeline_steps .tl_item[data-ts='" + newTs + "']");
  if (newItem) {
    toggleStepTimeline(newItem);
    updateTimelineDatetime(newTs, timeline.details);
    renderWindSensors(Object.values(data.timelineData[newTs] || {}));

    if (updatePosition) {
      updateTimelinePosition(newTs);
    }

    let isFuture = !newItem.classList.contains("tl_obs");
    if (isFuture && layers.dataLayers.indexOf('waves_fc') === -1) {
      if (layers.dataLayers.indexOf('wind_fc') === -1) {
        wm.toggleLayer(document.querySelector("#wind_fc"), 'wind_fc');
      }
    }
  }
  return newItem;
}

export let refreshDataLayers = debounce(function() {
  let ts = null, rendered = document.querySelector("#map_container").classList.contains('rendered');
  if (timeline.timelineActiveStep) {
    ts = parseInt(timeline.timelineActiveStep.getAttribute('data-ts'));
    if (!timeline.timelineActiveStep.getAttribute('data-fc')) {
      ts = ts - (ts % 3600);
    }
  }

  if (layers.dataLayers.indexOf('wind_fc') !== -1){
    if (map.windLayer) {
      map.windLayer.stop();
    }
    displayWindForecasts(ts).then(function () {
      if (map.currentFocus && config.popup) {
        displayForecastDetails(config.popup, map.currentFocus.lat, map.currentFocus.lng);
      }
    });
  }

  if (layers.dataLayers.indexOf('waves_fc') !== -1) {
    displayWavesForecasts(ts).then(function () {
      if (map.currentFocus && config.popup) {
        displayForecastDetails(config.popup, map.currentFocus.lat, map.currentFocus.lng);
      }
    });
  }

  if (layers.dataLayers.indexOf('weather_fc') !== -1) {
    displayPOIs(ts);
  }
  if (layers.dataLayers.indexOf('waves') !== -1) {
    if (timeline.timelineActiveStep) {
      hideWaves();
    } else {
      displayWaves();
    }
  }
  if (layers.dataLayers.indexOf('temp') !== -1) {
    if (timeline.timelineActiveStep) {
      hideTemperatures();
    } else {
      displayTemperatures();
    }
  }
}, 700);

function setUpKeyboardActions() {
  window.addEventListener("keydown", function(e) {
    switch(e.code) {
      case "ArrowLeft":
        e.stopPropagation();
        e.preventDefault();
        prevStepTimeline();
        break;
      case "ArrowRight":
        e.stopPropagation();
        e.preventDefault();
        nextStepTimeline();
        break;
      case "Escape":
        refreshTimeline();
        break;
      case "Space":
        e.stopPropagation();
        e.preventDefault();
        toggleTimeline(document.querySelector("#timeline_commands #tl_play"));
        break;
      default:
    }
  }, true);
  timeline.keyBoardSetUp = true;
}

function updateTimelinePosition(newVal) {
  const newStep = computeStep(newVal), xAdjustment = (timeline.details && !layout.mobile) ? 4 : 1;
  const xPos = timeline.details ? ((newStep + xAdjustment) * timeline.detailsStepWidth) : ((newStep + xAdjustment) * timeline.stepWidth);

  timeline.timelineSlider.scrollTo({x: xPos - (timeline.details ? timeline.detailsCursorOffset : timeline.cursorOffset), y: 0});
}

function computeStep(ts) {
  return timeline.timelineSteps.findIndex(s => s[0] <= ts && s[1] > ts);
}

function computeWeatherStep(ts) {
  let prevHour = ts - ts % 3600;
  return computeStep(prevHour);
}

